import {ALL_AUTHORS, Authors} from '@entities/common/authors';
import {IFeedback} from '@entities/components/feedback/interfaces';

export const ANGULAR_COURSE_FEEDBACKS: IFeedback.Recall[] = [
	{
		courseYear: 2021,
		text: `
			На начало курса у меня были знания React и
			половина пройденного туториала "Tour of Heroes" по Angular.
			По окончанию оказалось, что достаточно было базовых знаний
			JS и желание изучить новый фреймворк. Всех участников разделили по командам.
			У каждой команды были менторы. На протяжении обучения всегда чувствовалась
			поддержка от менторов и команды. Сам же курс несложный и прошел без
			сильного напряжения. Очень благодарен всем за такой опыт)
		`,
		author: Authors.Nikon,
		img: ALL_AUTHORS[Authors.Nikon].img,
	},
	{
		courseYear: 2021,
		text: `
			Для начала хотела бы выразить огромную благодарность всем,
			кто был причастен к этой инициативе проведения учебных
			курсов и стажировки на базе компании DataMola.
			JS Course - это действительно огромный шанс для всех желающих
			получить необходимые теоритические и практические навыки,
			научиться работать в команде и перенять ценный опыт своих кураторов.
			2 этап JS Course получился действительно достойным и результативным:
			организаторы старались устранить все лекционные потребности своих студентов,
			компетентные кураторы 24/7 были на связи со своей командой, чтобы подсказать
			или помочь в нужный момент.  И по итогу у нашей команды действительно
			получилось с нуля написать и презентовать рабочее оригинальное приложение,
			которое, по моему мнению, очень приближено к реальным проектам,
			что не может не радовать.  Хотелось бы отдельно уделить внимание
			взаимотноешениям в команде: нам удалось быстро найти общий язык друг с другом,
			мы часто помогали друг другу в процессе разработки совместного проекта,
			очень сдружились и продолжаем поддерживать связь после окончания JS Course.
			Я действительно рада, что попала именно в эту команду и у меня были
			самые классные кураторы: Яна, Саша и Леша, спасибо вам огромное!
		`,
		author: Authors.Alina,
		img: ALL_AUTHORS[Authors.Alina].img,
	},
	{
		courseYear: 2021,
		text: `
			Очень рад, что узнал о вашых курсах еще с первого этапа.
			Мне кажется, это одни из лучших курсов в Минске.
			Отдельное спасибо менторам, которые всегда на связи, всегда подскажут,
			помогут, выведут на рабочий лад, ответят  порой даже на глупейшие вопросы без всякой злости)
		`,
		author: 'Aleksandr Kupchenya',
		img: './assets/img/ph/male.jpeg',
	},
	{
		courseYear: 2021,
		text: `
			Курс обучает созданию реальных проектов, где можно увидеть целосность
			и отделить важные составляющие фронта от второстепенных.
			Такое не найдете ни на одних Ng-обучалках в сети.
			Помимо Angular, курс дает отличную тренировку в бизнес-аналитике,
			прокачке softskills в команде, ориентиры в Ui/UX,
			что также полезно для граммотного разработчика и любого профессионального роста.
		`,
		author: 'Yuliya Philippova',
		img: './assets/img/ph/female.jpeg',
	},
	{
		courseYear: 2021,
		text: `
			Мне понравился курс, была поставлена необычная задача и
			созданы условия для творческой работы, обеспечивающие
			возможность использования различных возможностей
			Ангуляра и ряда сторонних библиотек.
		`,
		author: Authors.Nikita,
		img: ALL_AUTHORS[Authors.Nikita].img,
	},
	{
		courseYear: 2021,
		text: `
			Компания Datamola предоставила отличную возможность для получения
			новых знаний и опыта работы.
			Было приятно работать с менторами, которые объясняли неочевидные
			моменты и направляли идеи, нашу "рабочую энергию" в нужное русло,
			если это было необходимо. Также не могу не отметить, что то,
			как сотрудники компании подошли к разработке курсов по JS и Angular
			и работе на их протяжении,  может охарактеризовать их как
			ответственных специалистов высокого уровня.
		`,
		author: Authors.Victoria,
		img: ALL_AUTHORS[Authors.Victoria].img,
	},
];
