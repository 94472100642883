import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {LoadingStatus} from '@entities/common/types';
import {ISelector} from '@entities/components/selector/interfaces';
import {fromEvent, Subscription} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'datamola-input-field-component',
	templateUrl: './input-field.component.html',
	styleUrls: ['./input-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent implements OnChanges {
	@Input() public readonly loadingStatus: LoadingStatus;
	@Input() public readonly selectedOption: ISelector.Option;

	@Output() public readonly opened = new EventEmitter();
	@Output() public readonly closed = new EventEmitter();

	@ViewChild('arrowContainer') private readonly arrowContainer: ElementRef;

	private isOpen = false;
	private subscription: Subscription;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedOption && this.isOpen) {
			this.onClick();
		}
	}

	public onClick(): void {
		this.isOpen = !this.isOpen ? this.onOpen() : this.onClose();
	}

	private onOpen(): boolean {
		this.opened.emit();
		this.upArrow();
		this.subscription = this.subscribeOnDOMClick();
		return true;
	}

	private upArrow(): void {
		(this.arrowContainer.nativeElement as HTMLElement).firstElementChild.classList.add('up');
	}

	private subscribeOnDOMClick(): Subscription {
		return fromEvent(document, 'click')
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.onClick();
			});
	}

	private onClose(): boolean {
		this.closed.emit();
		this.downArrow();
		this.subscription.unsubscribe();
		return false;
	}

	private downArrow(): void {
		(this.arrowContainer.nativeElement as HTMLElement).firstElementChild.classList.remove('up');
	}
}
