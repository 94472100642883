import {Authors} from '@entities/common/authors';
import {Courses, CoursesStatus} from '@entities/common/courses';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';
import {CoursesHelpers} from '@shared/helpers/courses.helper';
import {DATA_CAMPING_FEEDBACKS} from './feedbacks.constants';
import {DATA_CAMPING_PLAN} from './plan.constants';

export const DATA_CAMPINGS: ICourseSchedule.Course = {
	name: Courses.DWH,
	plan: DATA_CAMPING_PLAN,
	feedbacks: DATA_CAMPING_FEEDBACKS,
	description: () => CoursesHelpers.getDescription(DATA_CAMPINGS).pageDescription,
	iterations: [
		{
			isCurrent: false,
			logo: {courseYear: 2021},
			status: CoursesStatus.Started,
			authors: [Authors.Andrei, Authors.Kiryl, Authors.Vitaly],
			startDate: new Date('July 1, 2020'),
			endDate: new Date('August 1, 2020'),
			graduatedAmount: 8,
		},
		{
			isCurrent: false,
			status: CoursesStatus.Started,
			authors: [Authors.Kiryl],
			startDate: new Date('July 1, 2021'),
			endDate: new Date('August 1, 2021'),
			graduatedAmount: 9,
		},
		{
			isCurrent: true,
			status: CoursesStatus.Started,
			authors: [Authors.Kiryl, Authors.MaximDaniliouk, Authors.DmitryBukhatkin],
			startDate: new Date('July 4, 2022'),
			endDate: new Date('August 1, 2022'),
			graduatedAmount: 0,
		},
	],
};
