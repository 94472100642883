import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingStatus} from '@entities/common/types';
import {ISelector} from '@entities/components/selector/interfaces';

@Component({
	selector: 'datamola-dropdown-list-component',
	templateUrl: './dropdown-list.component.html',
	styleUrls: ['./dropdown-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownListComponent {
	@Input() public readonly loadingStatus: LoadingStatus;
	@Input() public readonly options: ISelector.Option[] = [];
	@Input() public readonly isVisible: boolean;

	@Output() public readonly selected = new EventEmitter();

	public onSelect(selectedOption: ISelector.Option): void {
		this.options.forEach((option: ISelector.Option) => {
			if (option === selectedOption) {
				option.isSelected = true;
				return;
			}
			if (option.isSelected) {
				option.isSelected = false;
			}
		});
		this.selected.emit();
	}
}
