<div class="container">
	<datamola-input-field-component
		[loadingStatus]="loadingStatus"
		[selectedOption]="selectedOption"
		(opened)="onOpen()"
		(closed)="onClose()"
	></datamola-input-field-component>
	<datamola-dropdown-list-component
		[isVisible]="isVisibleDropdown"
		[loadingStatus]="loadingStatus"
		[options]="options"
		(selected)="onSelect()"
	></datamola-dropdown-list-component>
</div>
