import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {PAGES} from '@entities/components/top-nav-bar/constants';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'datamola-top-nav-bar-component',
	templateUrl: './top-nav-bar.component.html',
	styleUrls: ['./top-nav-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavBarComponent implements AfterViewInit, OnDestroy {
	readonly pages = PAGES;
	public isSubMenuVisible = false;
	public menuToggle = false;
	private scrollEvent: Subscription;

	public constructor(private router: Router, private cdRef: ChangeDetectorRef) {
		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationStart) {
				this.menuToggle = false;
				this.isSubMenuVisible = false;
			}
		});
	}

	public ngAfterViewInit(): void {
		this.scrollEvent = fromEvent(document.body, 'scroll')
			.pipe(debounceTime(100))
			.subscribe(() => {
				this.isSubMenuVisible = false;
				this.cdRef.detectChanges();
			});
	}

	public ngOnDestroy(): void {
		this.scrollEvent?.unsubscribe();
	}

	public onSubMenuToggle(): void {
		this.isSubMenuVisible = !this.isSubMenuVisible;
	}
}
