import {Authors} from '@entities/common/authors';
import {Courses, CoursesStatus} from '@entities/common/courses';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';
import {ANGULAR_COURSE_FEEDBACKS} from './feedbacks.constants';
import {ANGULAR_COURSE_PLAN} from './plan.constants';

export const ANGULAR_COURSES: ICourseSchedule.Course = {
	name: Courses.Angular,
	plan: ANGULAR_COURSE_PLAN,
	feedbacks: ANGULAR_COURSE_FEEDBACKS,
	description: () => 'We organize full web development workflow. Students develop brand new projects from scratch.',
	iterations: [
		{
			isCurrent: false,
			status: CoursesStatus.Started,
			authors: [Authors.Eugene, Authors.Oleg, Authors.Yana, Authors.Yauheni, Authors.Alexandra, Authors.Pavel],
			startDate: new Date('February 1, 2021'),
			endDate: new Date('March 1, 2021'),
			graduatedAmount: 15,
		},
		{
			isCurrent: true,
			status: CoursesStatus.Started,
			authors: [
				Authors.Eugene,
				Authors.AndreySupryaga,
				Authors.Alexandra,
				Authors.Pavel,
				Authors.Alina,
				Authors.Nikon,
				Authors.SashaSary,
				Authors.Victoria,
				Authors.Yauheni,
				Authors.Bogdan,
				Authors.PavelLebedev,
				Authors.Timur,
				Authors.Nikita,
			],
			startDate: new Date('June 1, 2022'),
			endDate: new Date('August 10, 2022'),
			graduatedAmount: 14,
		},
	],
};
