import {ALL_AUTHORS, Authors} from '@entities/common/authors';
import {IFeedback} from '@entities/components/feedback/interfaces';

export const JS_COURSE_FEEDBACKS: IFeedback.Recall[] = [
	{
		courseYear: 2020,
		text: `
				Курс оправдал мои ожидания. Я получила базу, необходимую для дальнейшего
				продвижения в выбранном направлении. Могу сказать, что понравилось всё:
				от структуризации и организованности до преподнесения материала. Менторы молодцы,
				не было нудных занятий, и помогали каждому настолько, насколько это было возможно.
		`,
		author: Authors.Victoria,
		img: ALL_AUTHORS[Authors.Victoria].img,
	},
	{
		courseYear: 2020,
		text: `
				Курсом очень доволен. Особенно стоит отметить обратную связь
				тренеров и атмосферу, на других курсах на которых я был или
				видел тренеры почти не уделяли внимания на вопросы в общих
				чатах и ребята-однокурсники тоже не рвались помогать...
				Но нас не обделили вниманием и однокурсники тоже помогали
				особенно в конце. Обсуждать проблемы по чату в самом чате
				это круто как по мне. Нового для меня было 30% контента
				но некоторые моменты еще лучше усвоились.
				Также хорошая структура курса.
				Если делать все шаг за шагом то все очень понятно и логично.
				К сожалению мой проект далек от моего желаемого результата
				но все из-за нехватки времени(знаю что у других тоже были
				большие проблемы со временем) но в целом результатом доволен.
				Жду такого же интересного продолжения!) Спасибо!
		`,
		author: Authors.PavelLebedev,
		img: ALL_AUTHORS[Authors.PavelLebedev].img,
	},
	{
		courseYear: 2020,
		text: `
			Это были самые полезные курсы, на которых я была!
			Подача лекционного материала была очень доступной,
			а практические занятия всегда проходили очень продуктивно.
			Все тренеры максимально отзывчивые.  Задания всегда были интересными,
			нравилось ломать голову над ними) Спасибо большое, что раскрыли для меня JS,
			он намного глубже, чем может показаться сначала)
		`,
		author: 'Veronika Koroleva',
		img: './assets/img/ph/female.jpeg',
	},
];
