import {AssociativeObject} from '../interfaces';
import {OfficesEnums} from './enums';
import {IOffices} from './interfaces';

export const OFFICES_CONFIG: AssociativeObject<IOffices.Config> = {
	[OfficesEnums.Cities.Lewes]: {
		city: OfficesEnums.Cities.Lewes,
		country: 'USA',
		title: `${OfficesEnums.Cities.Lewes} head-office`,
		type: OfficesEnums.Types.HeadOffice,
		address: '16192 Coastal Hwy Lewes, DE 19958-3608, USA',
		telephone: ['(347) 474-1003'],
		class: OfficesEnums.Cities.Lewes.toLowerCase(),
		isHiring: false,
	},
	[OfficesEnums.Cities.Kyiv]: {
		city: OfficesEnums.Cities.Kyiv,
		country: 'Ukraine',
		title: `${OfficesEnums.Cities.Kyiv} office`,
		type: OfficesEnums.Types.DevelopmentOffice,
		address: 'st. Yevhena Sverstyuka, 2B, Kyiv, Ukraine',
		telephone: ['+380 (63) 190-38-98'],
		class: OfficesEnums.Cities.Kyiv.toLowerCase(),
		isHiring: false,
	},
	[OfficesEnums.Cities.Minsk]: {
		city: OfficesEnums.Cities.Minsk,
		country: 'Republic of Belarus',
		title: `${OfficesEnums.Cities.Minsk} office`,
		type: OfficesEnums.Types.DevelopmentOffice,
		address: 'village Kopishche, Lopatina st., 5-168, 220081, Minsk district, Minsk region, Republic of Belarus',
		telephone: ['+375 (17) 370-53-12'],
		class: OfficesEnums.Cities.Minsk.toLowerCase(),
		isHiring: true,
	},
	[OfficesEnums.Cities.Nicosia]: {
		city: OfficesEnums.Cities.Nicosia,
		country: 'Cyprus',
		title: `${OfficesEnums.Cities.Nicosia} office`,
		type: OfficesEnums.Types.DevelopmentOffice,
		address: '8 Kennedy Avenue, First Floor, Office 101, 1087, Nicosia, Cyprus',
		telephone: ['+357 (99) 919-203'],
		class: OfficesEnums.Cities.Nicosia.toLowerCase(),
		isHiring: true,
	},
	[OfficesEnums.Cities.Wroclaw]: {
		city: OfficesEnums.Cities.Wroclaw,
		country: 'Poland',
		title: `${OfficesEnums.Cities.Wroclaw} office`,
		type: OfficesEnums.Types.DevelopmentOffice,
		address: 'ul. Grabarska 1B, 50-079, Wrocław, Rzeczpospolita Polska',
		telephone: ['+48 (51) 515-05-74'],
		email: 'Info_pl@datamola.com',
		class: OfficesEnums.Cities.Wroclaw.toLowerCase(),
		isHiring: true,
	},
};
