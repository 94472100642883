import {NUMBERS} from '@entities/common/numbers';

export class NumberToWordHelper {
	public static convert(number: number): {adj: string; numeral: string} {
		const currentNumber =
			number > Object.keys(NUMBERS).length - 1
				? Array(3).join(number.toString()).split('')
				: Object.entries(NUMBERS)[number + 1];
		return {
			numeral: currentNumber[0],
			adj: currentNumber[1],
		};
	}
}
