import {Authors} from '@entities/common/authors';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';

export const ANGULAR_COURSE_PLAN: ICourseSchedule.PlanItem[] = [
	{
		title: 'Angular Practical Course Introduction',
		author: [Authors.Alexandra],
		summary: 'Course goals. Work process organisation. Acquaintance with mentors and distribution into groups.',
		lect: {
			hours: 2,
			count: 1,
		},
	},
	{
		title: 'Technical specification',
		author: [Authors.Kiryl, Authors.All],
		summary: `
					Live session with a customer.
					Defining what we want to get from application and what bussiness goals should the application meet.
					Based on this information students creates a technical specification.
					`,
		lect: {
			hours: 2,
			count: 1,
		},
	},
	{
		title: 'Application design',
		author: [Authors.All],
		summary: `
					Prepare a prototype and a web app design using Figma.
					Lecture recording from JS From Zero
					to Hero
		`,
		lect: {
			hours: 1.5,
			count: 1,
		},
	},
	{
		title: 'TypeScript and Angular Introduction',
		author: [Authors.All],
		summary: `
					TypeScript and Angular basics.<br />
					Live coding: how to create Angular applications.
					How to use it in practice.
		`,
		lect: {
			hours: 2,
			count: 1,
		},
	},
	{
		title: 'RxJS',
		author: [Authors.Eugene],
		summary: `
					Short review, key concepts and principles of RxJS.
					Deep dive in most popular RxJS usage cases. Practical usage on course project.
		`,
		lect: {
			hours: 1.5,
			count: 1,
		},
	},
	{
		title: 'Angular deep dive: part 1',
		author: [Authors.Yauheni],
		summary: `
					Directives, Pipes, Modules, Dependency injection.
					Live coding with examples. Practical usage on course project.
		`,
		lect: {
			hours: 1.5,
			count: 1,
		},
	},
	{
		title: 'Course project DEMO',
		author: [Authors.Kiryl, Authors.All],
		summary: 'Presenting project to customer, feedback and futher project goals.',
		lect: {
			hours: 1.5,
			count: 1,
		},
	},
	{
		title: 'Angular deep dive: part 2',
		author: [Authors.Yauheni],
		summary: `
					Server communication, Routing, Component lifecycle, Forms.
					Live coding with examples. Practical usage on course project.
		`,
		lect: {
			hours: 1.5,
			count: 1,
		},
	},

	{
		title: 'Final Presentation',
		author: [Authors.Kiryl, Authors.All],
		summary: `
					Demonstration of application, work overview.
					Used technologies: Git, Angular, Angular Material, Scss, RxJs.
		`,
		lect: {
			hours: 2,
			count: 1,
		},
	},
	{
		title: 'Are you ready for interview?',
		author: [Authors.All],
		summary: ' Analysis of typical interview questions and tasks.',
		lect: {
			hours: 1.5,
			count: 1,
		},
	},
];
