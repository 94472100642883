import {ERouting} from './enums';

export const LazyModules: Record<ERouting.LazyModulePaths, () => Promise<any>> = {
	[ERouting.LazyModulePaths.AngularCourse]: () =>
		import('@pages/angular-course/angular-course.module').then(({AngularCourseModule}) => AngularCourseModule),
	[ERouting.LazyModulePaths.AiDroneEducationCenter]: () =>
		import('@pages/ai-drone-education-center/ai-drone-education-center.module').then(
			({AiDroneEducationCenterModule}) => AiDroneEducationCenterModule
		),
	[ERouting.LazyModulePaths.DataMill]: () =>
		import('@pages/datamill/datamill.module').then(({DataMillModule}) => DataMillModule),
	[ERouting.LazyModulePaths.DataCamping]: () =>
		import('@pages/data-camping/data-camping.module').then(({DataCampingModule}) => DataCampingModule),
	[ERouting.LazyModulePaths.DwhLearningPath]: () =>
		import('@pages/dwh-learning-path/dwh-learning-path.module').then(
			({DwhLearningPathModule}) => DwhLearningPathModule
		),
	[ERouting.LazyModulePaths.JsCourse]: () =>
		import('@pages/js-course/js-course.module').then(({JsCourseModule: JsCourseModule}) => JsCourseModule),
	[ERouting.LazyModulePaths.WeAreHiring]: () =>
		import('@pages/we-are-hiring/we-are-hiring.module').then(({WeAreHiringModule}) => WeAreHiringModule),
	[ERouting.LazyModulePaths.JsLearningPath]: () =>
		import('@pages/js-learning-path/js-learning-path.module').then(
			({JsLearningPathModule}) => JsLearningPathModule
		),
	[ERouting.LazyModulePaths.Main]: () => import('@pages/main/main.module').then(({MainModule}) => MainModule),
};
