export namespace OfficesEnums {
	export enum Cities {
		Minsk = 'Minsk',
		Kyiv = 'Kyiv',
		Wroclaw = 'Wroclaw',
		Nicosia = 'Nicosia',
		Lewes = 'Lewes',
	}

	export enum Types {
		HeadOffice = 'Head-office',
		DevelopmentOffice = 'Development-office',
	}
}
