import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import {LoadingStatus} from '@entities/common/types';
import {ISelector} from '@entities/components/selector/interfaces';

@Component({
	selector: 'datamola-selector-component',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorComponent implements OnChanges {
	@Input() public readonly loadingStatus: LoadingStatus;
	@Input() public readonly options: ISelector.Option[];

	@Input() public selectedOption: ISelector.Option;

	@Output() public readonly opened = new EventEmitter();
	@Output() public readonly closed = new EventEmitter();
	@Output() public readonly selected = new EventEmitter<number>();

	public isVisibleDropdown = false;

	@HostListener('click', ['$event'])
	private onClick(event$: Event): void {
		event$.stopPropagation();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.options?.currentValue) {
			// this.selectedOption = this.getSelectedOption();
		}
	}

	private getSelectedOption(): ISelector.Option {
		const selectedOption = this.options.find((option: ISelector.Option) => option.isSelected);
		return selectedOption ? {...selectedOption} : {...this.selectedOption};
	}

	private getSelectedOptionIndex(): number {
		const findIndex = this.options.findIndex((item) => item.value === this.selectedOption.value);
		return findIndex < 0 ? 0 : findIndex;
	}

	public onOpen(): void {
		this.isVisibleDropdown = true;
		this.opened.emit();
	}

	public onClose(): void {
		this.isVisibleDropdown = false;
		this.closed.emit();
	}

	public onSelect(): void {
		this.selectedOption = this.getSelectedOption();
		this.selected.emit(this.getSelectedOptionIndex());
	}
}
