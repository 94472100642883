import {Authors} from '@entities/common/authors';
import {Courses, CoursesStatus} from '@entities/common/courses';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';
import {CoursesHelpers} from '@shared/helpers/courses.helper';
import {JS_COURSE_FEEDBACKS} from './feedbacks.constants';
import {JS_COURSE_PLAN} from './plan.constants';

export const JS_COURSES: ICourseSchedule.Course = {
	name: Courses.JS,
	plan: JS_COURSE_PLAN,
	feedbacks: JS_COURSE_FEEDBACKS,
	description: () => CoursesHelpers.getDescription(JS_COURSES).pageDescription,
	iterations: [
		{
			isCurrent: false,
			status: CoursesStatus.Started,
			authors: [Authors.Yana, Authors.Alexandra, Authors.Yauheni],
			startDate: new Date('October 1, 2020'),
			endDate: new Date('December 1, 2020'),
			graduatedAmount: 18,
		},
		{
			isCurrent: false,
			status: CoursesStatus.Started,
			authors: [
				Authors.Yana,
				Authors.Yauheni,
				Authors.Alexandra,
				Authors.Eugene,
				Authors.AndreySupryaga,
				Authors.Alina,
				Authors.Nikon,
			],
			startDate: new Date('February 14, 2022'),
			endDate: new Date('April 1, 2022'),
			graduatedAmount: 15,
		},
		{
			isCurrent: true,
			status: CoursesStatus.Started,
			authors: [
				Authors.Alina,
				Authors.Alexandra,
				Authors.Eugene,
				Authors.Nikon,
				Authors.Victoria,
				Authors.PavelLebedev,
				Authors.Bogdan,
				Authors.Nikita,
			],
			startDate: new Date('February 10, 2023'),
			endDate: new Date('April 19, 2023'),
			graduatedAmount: 0,
		},
	],
};
