import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {RoutingHelper} from '@shared/helpers/routing.helper';

const routes: Routes = [...RoutingHelper.getAllPagesConfig(), {path: '**', redirectTo: ''}];

@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
