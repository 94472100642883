<div *ngIf="loadingStatus?.loaded; else dataIsNotLoaded" #arrowContainer class="input-field" (click)="onClick()">
	{{ selectedOption.name }}
	<span class="iconify" data-icon="mdi:chevron-down" data-inline="false"></span>
</div>
<ng-template #dataIsNotLoaded>
	<div class="input-field" #arrowContainer>
		Data is not loaded
		<span class="iconify" data-icon="mdi:chevron-down" data-inline="false"></span>
	</div>
</ng-template>
