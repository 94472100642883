import {ALL_AUTHORS, Authors} from '@entities/common/authors';
import {Courses} from '@entities/common/courses';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';
import {ContactUsHelper} from './contact-us.helper';
import {NumberToWordHelper} from './number-to-word.helper';

export class CoursesHelpers {
	public static mapAuthors(authors: Authors[]): ICourseSchedule.Author[] {
		return authors.map((author) => ALL_AUTHORS[author] as ICourseSchedule.Author);
	}

	public static getDescription(course: ICourseSchedule.Course): {
		carouselDescription: string;
		pageDescription: string;
	} {
		let carouselDescription = '';
		let pageDescription = '';
		const startDate = course.iterations[course.iterations.length - 1].startDate.toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
		});
		const isCourseStarted = ContactUsHelper.isCourseStarted(course);
		switch (course.name) {
			case Courses.DWH: {
				const courseNumber = NumberToWordHelper.convert(course.iterations.length - 1).numeral;
				const actionCarouselStatus = isCourseStarted
					? 'will announce new one soon'
					: 'already announce new one';
				const actionPageStatus = isCourseStarted ? 'It already started' : "It's going to start";
				carouselDescription = `Already ${courseNumber} streams of
				<span class="green-text strong"> ${Courses.DWH} </span>
				was finished, and we ${actionCarouselStatus}.`;
				pageDescription = `will help you to start your career and become an awesome DWH developer.
				${actionPageStatus} on ${startDate}.`;
				break;
			}
			case Courses.JS: {
				const courseNumber = NumberToWordHelper.convert(course.iterations.length - 1).adj;
				const actionPageStatus = isCourseStarted ? 'already started' : 'will start';
				carouselDescription = `<span class="purple-text strong">${Courses.JS}</span>
					- start efficiently the long way of becoming a demanded frontend developer.`;
				pageDescription = `For the ${courseNumber} time we are proud to announce our 
				JS Course for beginners. It ${actionPageStatus} on ${startDate}.`;
				break;
			}
		}
		return {carouselDescription, pageDescription};
	}

	public static orderAccordingStatus(course: ICourseSchedule.Course, order: number): number {
		return ContactUsHelper.isCourseRecruitmentClosed(course) ? order : 0;
	}
}
