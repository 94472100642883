<div class="row wide footer-row">
	<div class="footer-col">
		<div class="content">
			<div class="content-col">
				<a
					class="option"
					[class]="{'uppercase-option': option.isUppercase, 'no-link': !option.link}"
					[href]="option.link"
					*ngFor="let option of firstColumnConfig"
				>
					{{ option.title }}
				</a>
				<div class="social-info">
					<a href="https://www.facebook.com/DataMolaLLC" class="facebook" target="_blank"> </a>
					<a href="https://www.instagram.com/datamola_llc/" class="instagram" target="_blank"> </a>
					<a href="https://www.linkedin.com/company/datamola" class="linkedin" target="_blank"> </a>
				</div>
			</div>
			<div class="content-col">
				<a
					class="option"
					[href]="option.link"
					[class]="{'uppercase-option': option.isUppercase, strong: !!i, 'no-link': !option.link}"
					*ngFor="let option of secondColumnConfig; let i = index"
				>
					{{ option.title }}<br />
					<span class="strong" *ngIf="option.subtitle" [class]="{'uppercase-option': option.isUppercase}">
						{{ option.subtitle }}
					</span>
				</a>
			</div>
			<div class="content-col office-col">
				<a
					class="option"
					[href]="option.link"
					[class]="{'uppercase-option': option.isUppercase, 'no-link': !option.link}"
					*ngFor="let option of thirdColumnConfig"
				>
					{{ option.title }}<br />
					<span class="strong" *ngIf="option.subtitle" [class]="{'uppercase-option': option.isUppercase}">
						{{ option.subtitle }}
					</span>
				</a>
			</div>
		</div>
		<div class="row footer wide">
			<img class="footer-logo" src="/assets/img/dm-logo-full.svg" alt="Datamola logo" />
			<span class="copyright"
				>&copy; 2018 &ndash; {{ currentYear }} &bull; datamola llc &bull; datamola cy limited</span
			>
		</div>
	</div>
</div>
