export enum Authors {
	All = 'All mentors',
	Alexandra = 'Alexandra Karpova',
	Andrei = 'Andrei Shorom',
	AndreySupryaga = 'Andrey Supryaga',
	Eugene = 'Eugene Humala',
	Kiryl = 'Kiryl Bucha',
	Oleg = 'Oleg Muraviov',
	Pavel = 'Pavel Sashnikau',
	Vitaly = 'Vitaly Butoma',
	Yana = 'Yana Yaroshevich',
	Yauheni = 'Yauheni Zhybryk',
	Alina = 'Alina Kulikova',
	Nikon = 'Nikon Galinovsky',
	SashaSary = 'Alexandr Sary',
	Victoria = 'Victoria Yaroshevich',
	Bogdan = 'Bogdan Nasekailo',
	Timur = 'Timur Kamalov',
	PavelLebedev = 'Pavel Lebedev',
	Nikita = 'Nikita Klusovich',
	MaximDaniliouk = 'Maxim Daniliouk',
	DmitryBukhatkin = 'Dmitry Bukhatkin',
	DronePilot = 'Drone Pilot',
}

export const ALL_AUTHORS = {
	[Authors.Kiryl]: {
		name: Authors.Kiryl,
		txt: `DWH Architect with more than 10 years’ experience in Data Modeling and more
		than 8 years of designing, developing and implementing numerous DWH applications
		including Planning/Budgeting, Sales and Data Analysis, Expense & Revenue Allocations,
		Telecom and Healthcare domain. The first trainer with a Resource Development Gold Award.`,
		img: './assets/img/ph/kiryl.jpeg',
	},
	[Authors.Vitaly]: {
		name: Authors.Vitaly,
		txt: `DevOps Engineer with Computer Science Master Degree.
		Young Superhero who combines an understanding of both engineering and coding.
		Improves CI and CD processes daily on different projects and takes care of developer's calm life.
		Nobody knows how much programming languages he really knows, looks like more than 10.`,
		img: './assets/img/ph/vitaly.jpeg',
	},
	[Authors.Andrei]: {
		name: Authors.Andrei,
		txt: `Data Engineer with more than 8 years of experience in analysis, design,
		development and managing projects. Enthusiast with great practical skills
		in full lifecycle of business systems including system analysis,
		design, development, deployment and reporting. Definitely a professional with strong
		knowledge of Data warehousing concepts.`,
		img: './assets/img/ph/andrei.jpeg',
	},
	[Authors.Yana]: {
		name: Authors.Yana,
		txt: `Senior Software Engineer with more than 5 years of experience
		in frontend development and project management.
		Has Computer Science Master Degree and PSM-I certificate.
		Yana's proved to be an experienced mentor, she has a willing to share her knowledge and unlock your potential.`,
		img: './assets/img/ph/yana.jpg',
	},
	[Authors.Alexandra]: {
		name: Authors.Alexandra,
		txt: `An Unicorn developer that for the last 5 years has been trying to combine work
		at UI/UX and frontend development at the same time.
		Also has experience in graphic and web design, Java programming and business analysis.
		Always tries to teach people how to generate creative ideas
		and transform them to convenient user interfaces.`,
		img: './assets/img/ph/sasha.jpeg',
	},
	[Authors.Pavel]: {
		name: Authors.Pavel,
		txt: `Experienced Software Engineer with competence in Frontend development.
		Started his career as a hobby and continued in DataMola a few years ago.
		Big fan of codewars, enjoys learning new things from UI world.`,
		img: './assets/img/ph/pasha.jpg',
	},
	[Authors.Oleg]: {
		name: Authors.Oleg,
		txt: `Lead JS Developer with 11 years of hard working experience.
		Believes in math and beauty of the world.`,
		img: './assets/img/ph/oleg.jpg',
	},
	[Authors.Eugene]: {
		name: Authors.Eugene,
		txt: `Bearded developer with experience developing mobile and web apps.
		Inspired by useful and effective interfaces. Loves to mentor and collaborate.`,
		img: './assets/img/ph/eugene.jpg',
	},
	[Authors.Yauheni]: {
		name: Authors.Yauheni,
		txt: `Senior UI developer with 7+ years of experience. Has been working in the
		education field for more than 10 years. Yauheni loves sharing his knowledge and
		experience because he believes that explaining something to others is the best way
		to learn it in depth. Fan of math, algorithms and punk rock.`,
		img: './assets/img/ph/yauheni.jpg',
	},
	[Authors.AndreySupryaga]: {
		name: Authors.AndreySupryaga,
		txt: `For more than 7 years he has been enjoying solving complex problems.
		Sharing experiences and helping others to reveal their inner potential is his
		vocation in life. Loves family, programming and yoga which helps to find
		balance in all spheres of life.`,
		img: './assets/img/ph/andreySupryaga.jpg',
	},
	[Authors.Alina]: {
		name: Authors.Alina,
		txt: `She really enjoys combining Frontend Engineering and UX/UI design at the
		same time, initially as a hobby and now as her job. Always tries to be the best
		in her field, shares the gained experience and useful practical skills with great
		pleasure. Loves creative-minded people full of innovative ideas.`,
		img: './assets/img/ph/alinaKulikava.jpg',
	},
	[Authors.Nikon]: {
		name: Authors.Nikon,
		txt: `UI Developer with experience in Angular and Flutter applications development.
		Also has Back-end skills. Worked with such technologies as Django, Flask, Express.
		Loves experiments, new perspective technologies, and enjoys sharing the knowledge
		and experience he has acquired.`,
		img: './assets/img/ph/nikonGalinovsky.jpg',
	},
	[Authors.SashaSary]: {
		name: Authors.SashaSary,
		txt: `JS/Flutter developer. As a student of the first JS Camping,
		I want to share my experience with new students. Big fan of board games.`,
		img: './assets/img/ph/alexandrSary.jpeg',
	},
	[Authors.Victoria]: {
		name: Authors.Victoria,
		txt: `JS developer with a desire to teach competent development and help expand
		the boundaries of consciousness. A peaceful fighter in life and in programming.
		The person is incredible and infinitely strong and her task is to prove it.`,
		img: './assets/img/ph/victoriaYaroshevich.jpg',
	},
	[Authors.Bogdan]: {
		name: Authors.Bogdan,
		txt: `A young developer who is sure that programming also requires creativity.
		Ever since school time, wanted to become a programmer, and now wants to help
		others join this sphere.  `,
		img: './assets/img/ph/bogdanNasekailo.jpeg',
	},
	[Authors.Timur]: {
		name: Authors.Timur,
		txt: 'A developer who is ready to help students with any questions at any time.',
		img: './assets/img/ph/timurKamalov.jpeg',
	},
	[Authors.PavelLebedev]: {
		name: Authors.PavelLebedev,
		txt: 'Joined the team after successfully completing courses, likes to share his knowledge.',
		img: './assets/img/ph/pavelLebedev.jpeg',
	},
	[Authors.Nikita]: {
		name: Authors.Nikita,
		txt: `JS developer. Student of the first JS Course. As it seems to me that the courses
		are a great opportunity to improve your and my skills. Keen on active kinds of sports.`,
		img: './assets/img/ph/nikitaKlusovich.jpeg',
	},
	[Authors.MaximDaniliouk]: {
		name: Authors.MaximDaniliouk,
		txt: `Expert project manager and DWH developer with over 10 years of commercial experience.
		He always shares his knowledge and experience, helps others to discover their inner potential.`,
		img: './assets/img/ph/maximDaniliouk.jpg',
	},
	[Authors.DmitryBukhatkin]: {
		name: Authors.DmitryBukhatkin,
		txt: `A person born for data engineering and enjoying it. Adept of reliable and bug-free
		code, guru of optimization and refactoring, which is what he expects from students.`,
		img: './assets/img/ph/male.jpeg',
	},
	[Authors.DronePilot]: {
		name: Authors.DronePilot,
		txt: `Attention all drone pilots! We are looking for a
		passionate and experienced drone pilot who is willing to develop and maintain education
		program with us. This is an exciting opportunity to share your knowledge and expertise with
		aspiring drone pilots and children and make a difference in the drone industry.`,
		img: './assets/img/ph/male.jpeg',
	},
};
