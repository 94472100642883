import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserWindowModule} from '@shared/components/browser-window/browser-window.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SelectorModule} from '@shared/components/selector/selector.module';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TopNavBarModule} from '@shared/components/top-nav-bar/top-nav-bar.module';
import {FooterModule} from '@shared/components/footer/footer.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		BrowserWindowModule,
		HttpClientModule,
		ReactiveFormsModule,
		SelectorModule,
		BrowserAnimationsModule,
		TopNavBarModule,
		FooterModule,
	],
	providers: [HttpClient],
	bootstrap: [AppComponent],
})
export class AppModule {}
