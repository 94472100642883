import {LazyModules} from '@entities/routing/constants';
import {Routes} from '@angular/router';

export class RoutingHelper {
	public static getAllPagesConfig(): Routes {
		const pagesConfig: Routes = [];
		Object.keys(LazyModules).forEach((path: string) => {
			pagesConfig.push({
				path,
				loadChildren: LazyModules[path],
			});
		});
		return pagesConfig;
	}
}
