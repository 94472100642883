import {CoursesStatus} from '@entities/common/courses';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';
import {ISelector} from '@entities/components/selector/interfaces';

export class ContactUsHelper {
	public static getSelectedOption(option: ISelector.Option): ISelector.Option {
		return {...option, isSelected: true};
	}

	public static isCourseStarted(course: ICourseSchedule.Course): boolean {
		const startDate = this.getCurrentCourse(course).startDate;
		return startDate.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
	}

	public static isCourseRecruitmentClosed(course: ICourseSchedule.Course): boolean {
		const currentStatus = this.getCurrentCourse(course).status;

		if (currentStatus === CoursesStatus.Started) {
			return true;
		}

		return (
			this.isCourseStarted(course) ||
			!(currentStatus === CoursesStatus.ToBeAnnounced || currentStatus === CoursesStatus.Interviews)
		);
	}

	public static getCurrentCourse(data: ICourseSchedule.Course): ICourseSchedule.CourseItem {
		return data.iterations.find((course) => course.isCurrent) || data.iterations[data.iterations.length - 1];
	}
}
