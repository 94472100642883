<div class="menu-overlay" *ngIf="menuToggle" (click)="menuToggle = !menuToggle"></div>
<div class="menu row wide">
	<a class="logo-container" routerLink="/">
		<div class="logo"></div>
	</a>
	<img class="menu-btn-img pointer" src="./assets/img/menu.svg" alt="menu" (click)="menuToggle = !menuToggle" />
	<menu class="menu" [class.menu-show]="menuToggle">
		<li class="menu-item" *ngFor="let page of pages" [class.hidden]="!page.isVisible">
			<a
				*ngIf="!page.pages && page.isVisible"
				class="menu-link"
				routerLinkActive="active"
				[style.--highlighted-color]="page.highlightedColor"
				[class.highlighted]="page.highlighted"
				[routerLink]="page.link"
				[routerLinkActiveOptions]="{exact: true}"
			>
				{{ page.title }}
			</a>
			<div
				*ngIf="page.pages"
				class="menu-dropdown"
				[class.active]="rla.isActive"
				[class.visible]="isSubMenuVisible"
				(click)="onSubMenuToggle()"
			>
				<div class="menu-dropdown-head">
					{{ page.title }}
					<span class="iconify chevron" data-icon="mdi:chevron-down" data-inline="false"></span>
				</div>
				<div class="submenu" routerLinkActive #rla="routerLinkActive" [class.visible]="isSubMenuVisible">
					<a
						*ngFor="let subpage of page.pages"
						class="submenu-link"
						routerLinkActive="active"
						[routerLink]="subpage.link"
						[routerLinkActiveOptions]="{exact: true}"
						(click)="onSubMenuToggle()"
					>
						<span class="text">{{ subpage.subtitle }}</span>
						<span class="strong title">{{ subpage.title }}</span>
					</a>
				</div>
			</div>
		</li>
	</menu>
</div>
