import {ERouting} from '@entities/routing/enums';
import {ControllersColor} from './colors';

export enum Courses {
	JS = 'JS From Zero To Hero',
	Angular = 'Angular Course',
	DWH = 'Data Camping',
	Drone = 'AI Drone Education Center',
	DataMill = 'Become comfortable with your data',
}

export enum CoursesStatus {
	ToBeAnnounced = 'to be announced',
	Interviews = 'interviews',
	Started = 'started',
	Archived = 'archived',
}

export const COURSES_BUTTONS = {
	text: 'JOIN COURSE',
	colors: {
		[Courses.JS]: ControllersColor.Purple,
		[Courses.Angular]: ControllersColor.Purple,
		[Courses.DWH]: ControllersColor.Green,
		[Courses.Drone]: ControllersColor.Yellow,
		[Courses.DataMill]: ControllersColor.Purple,
	},
};

export const LOGO_TRANSFORM = {
	[ControllersColor.Purple]: 'translateY(-50%) rotate(-150deg)',
	[ControllersColor.Yellow]: 'translateY(-50%) rotate(-60deg)',
	[ControllersColor.Green]: 'translateY(-50%) rotate(30deg)',
};

export const COURSES_ROUTES = {
	[Courses.JS]: ERouting.LazyModulePaths.JsCourse,
	[Courses.Angular]: ERouting.LazyModulePaths.AngularCourse,
	[Courses.DWH]: ERouting.LazyModulePaths.DataCamping,
	[Courses.Drone]: ERouting.LazyModulePaths.AiDroneEducationCenter,
	[Courses.DataMill]: ERouting.LazyModulePaths.DataMill,
};

export const COURSES_LOGOS = {
	commonPath: 'assets/img/courses',
	extension: 'svg',
	folderName: {
		[Courses.JS]: 'js',
		[Courses.Angular]: 'angular',
		[Courses.DWH]: 'dwh',
		[Courses.Drone]: 'drone',
		[Courses.DataMill]: 'datamill',
	},
};

export const COURSES_CONTACT_INDEX = {
	[Courses.DWH]: 1,
	[Courses.JS]: 2,
	[Courses.Angular]: 2,
	[Courses.Drone]: 0,
	[Courses.DataMill]: 0,
};
