import {IFooter} from './interfaces';
import {PAGES} from '@entities/components/top-nav-bar/constants';
import {ITopNavBar} from '@entities/components/top-nav-bar/interfaces';
import {OFFICES_CONFIG} from '@entities/common/offices/constants';

function getFirstColumnConfig(headerMenu: ITopNavBar.MenuItem[]): IFooter.Config[] {
	const footerConfig: IFooter.Config[] = [];
	headerMenu.forEach((item: ITopNavBar.MenuItem) => {
		if (item.pages) {
			return;
		}
		if (item.isVisible)
			footerConfig.push({
				...item,
				isUppercase: true,
			});
	});
	return footerConfig;
}

function getSecondColumnConfig(headerMenu: ITopNavBar.MenuItem[]): IFooter.Config[] {
	const footerConfig: IFooter.Config[] = [];
	headerMenu.forEach((item: ITopNavBar.MenuItem) => {
		if (!item.pages) {
			return;
		}
		footerConfig.push({
			title: item.title,
			link: item.link,
			isUppercase: true,
		});
		item.pages.forEach((page: ITopNavBar.Page) => {
			footerConfig.push({
				...page,
				isUppercase: false,
			});
		});
	});
	return footerConfig;
}

function getThirdColumnConfig(): IFooter.Config[] {
	const footerConfig: IFooter.Config[] = [
		{
			isUppercase: true,
			link: '',
			title: 'contacts',
		},
	];
	Object.values(OFFICES_CONFIG).forEach((item) => {
		footerConfig.push({
			isUppercase: false,
			link: `tel:${item.telephone[0]}`,
			subtitle: item.telephone[0],
			title: item.title,
		});
	});
	return footerConfig;
}

export const FIRST_COLUMN_CONFIG: IFooter.Config[] = [...getFirstColumnConfig(PAGES)];

export const SECOND_COLUMN_CONFIG: IFooter.Config[] = [...getSecondColumnConfig(PAGES)];

export const THIRD_COLUMN_CONFIG: IFooter.Config[] = [...getThirdColumnConfig()];
