import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';

export const JS_COURSE_PLAN: ICourseSchedule.PlanItem[] = [
	{
		name: '1 Unit',
		title: 'Introduction to Web Applications',
		summary: `
					Course goals. Web App architecture.<br>Version Control Systems.
					Technologies overview.<br>Web Design. User Experience.
		`,
		lect: {
			hours: 3,
			count: 2,
		},
		lab: {
			hours: 6,
			count: 4,
		},
	},
	{
		name: '2 Unit',
		title: 'Layout and Styles',
		summary: 'HTML, CSS, Media queries, responsive design,<br>Chrome Dev Tools.',
		lect: {
			hours: 4.5,
			count: 3,
		},
		lab: {
			hours: 6,
			count: 4,
		},
	},
	{
		name: '3 Unit',
		title: 'JS Intro',
		summary: `
					Code structure. Variables and Types. Logical and<br>Conditional operators.
					Objects, Reference types, Arrays.<br>Functions, Lexical Environment. Chrome Dev Tools.
		`,
		lect: {
			hours: 3,
			count: 2,
		},
		lab: {
			hours: 6,
			count: 4,
		},
	},
	{
		name: '4 Unit',
		title: 'JS Deep Dive and Best Practices',
		summary: `
					Algorithm Complexity. Data Structures.<br>Constructors, Prototypes, Inheritance. ES6 syntax.<br>
					Clean Code best practices. MVC architecture.`,
		lect: {
			hours: 3,
			count: 2,
		},
		lab: {
			hours: 6,
			count: 4,
		},
	},
	{
		name: '5 Unit',
		title: 'DOM, BOM',
		summary: 'Browser Rendering. Browser Object Model.<br>Document Object Model.<br>Class HtmlTemplate. Forms.',
		lect: {
			hours: 1.5,
			count: 1,
		},
		lab: {
			hours: 3,
			count: 2,
		},
	},
	{
		name: '6 Unit',
		title: 'Events, Browser Storage',
		summary: 'Events, Event handlers, Event Life Cycle.<br>Events Delegation. Browser Storage.',
		lect: {
			hours: 1.5,
			count: 1,
		},
		lab: {
			hours: 3,
			count: 2,
		},
	},
	{
		name: '7 Unit',
		title: 'Networks, Async, HTTP',
		summary: `
					BOM interfaces for interaction with HTTP.<br>XMLHttpRequest, Ajax.
					Promises, Callbacks,<br>Async - Await.
					Fetch API. Event Loop, Workers.<br>Error handling. Web Sockets.
		`,
		lect: {
			hours: 3,
			count: 2,
		},
		lab: {
			hours: 6,
			count: 4,
		},
	},
	{
		name: '8 Unit',
		title: 'Final',
		summary: 'Results. Resources review.<br>2nd iteration plan review.',
		lect: {
			hours: 1,
			count: 1,
		},
		lab: {
			hours: 3,
			count: 2,
		},
	},
];
