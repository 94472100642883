import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FIRST_COLUMN_CONFIG, SECOND_COLUMN_CONFIG, THIRD_COLUMN_CONFIG} from '@entities/components/footer/constants';

@Component({
	selector: 'datamola-footer-component',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	public readonly firstColumnConfig = FIRST_COLUMN_CONFIG;
	public readonly secondColumnConfig = SECOND_COLUMN_CONFIG;
	public readonly thirdColumnConfig = THIRD_COLUMN_CONFIG;
	public readonly currentYear = new Date().getFullYear();
}
