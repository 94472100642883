import {NgModule} from '@angular/core';
import {TopNavBarComponent} from '@shared/components/top-nav-bar/top-nav-bar.component';
import {TopNavBarContainer} from '@shared/components/top-nav-bar/top-nav-bar.container';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@NgModule({
	declarations: [TopNavBarComponent, TopNavBarContainer],
	exports: [TopNavBarContainer],
	imports: [CommonModule, RouterModule],
})
export class TopNavBarModule {}
