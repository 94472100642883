import {NgModule} from '@angular/core';
import {SelectorContainer} from '@shared/components/selector/selector.container';
import {SelectorComponent} from '@shared/components/selector/selector.component';
import {CommonModule} from '@angular/common';
import {InputFieldComponent} from '@shared/components/selector/input-field/input-field.component';
import {DropdownListComponent} from '@shared/components/selector/dropdown-list/dropdown-list.component';

@NgModule({
	declarations: [SelectorContainer, SelectorComponent, InputFieldComponent, DropdownListComponent],
	exports: [SelectorContainer],
	imports: [CommonModule],
})
export class SelectorModule {}
