export namespace ERouting {
	export enum LazyModulePaths {
		AngularCourse = 'angular-course',
		DataCamping = 'data-camping',
		AiDroneEducationCenter = 'drone-school',
		DataMill = 'datamill',
		DwhLearningPath = 'dwh-learning-path',
		JsCourse = 'js-from-zero-to-hero',
		WeAreHiring = 'we-are-hiring',
		JsLearningPath = 'js-learning-path',
		Main = '',
	}
}
