import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {ISelector} from '@entities/components/selector/interfaces';
import {LoadingStatus} from '@entities/common/types';
import {DEFAULT_SELECTED_OPTION} from '@entities/components/selector/constants';

@Component({
	selector: 'datamola-selector',
	template: `
		<datamola-selector-component
			[loadingStatus]="loadingStatus"
			[options]="options"
			[selectedOption]="selectedOption"
			(opened)="onOpen()"
			(closed)="onClose()"
			(selected)="selected.emit($event)"
		></datamola-selector-component>
	`,
	styles: [':host { cursor: pointer; }'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorContainer {
	@Input() readonly loadingStatus: LoadingStatus;
	@Input() readonly options: ISelector.Option[];
	@Input() readonly selectedOption = DEFAULT_SELECTED_OPTION;

	@Output() selected = new EventEmitter<number>();

	constructor(private elementRef: ElementRef) {}

	public onOpen(): void {
		(this.elementRef.nativeElement as HTMLElement).style.borderRadius = '0';
	}

	public onClose(): void {
		(this.elementRef.nativeElement as HTMLElement).style.borderRadius = '';
	}
}
