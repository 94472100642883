import {IFeedback} from '@entities/components/feedback/interfaces';

export const DATA_CAMPING_FEEDBACKS: IFeedback.Recall[] = [
	{
		courseYear: 2020,
		text: `
			Лекции:<br/>
			Понравилось, что по всем темам есть методы.
			Хороший преподаватель, главное не стесняться задавать вопросы.<br/>
			Лабы:<br/>
			Очень хорошие лабы в план тем, которые они раскрывают.
			Но часто надо по сути самому себе придумывать задания,
			для меня это было сложно. Также нету постоянной проверки лаб,
			но можно самому попросить посмотреть.<br/>
			Итог:</br>
			Курсы дают много уникальных знаний. Будет полезно любому программисту,
			так как БД есть почти во всех проектах."
		`,
		author: 'Igor Surikov',
		img: './assets/img/ph/male.jpeg',
	},
	{
		courseYear: 2021,
		text: `
			Эти курсы дают необходимую базу начального уровня и понимание того,
			как всё устроено, а также делают вход в профессию не таким страшным,
			как это может показаться новичку. Благодаря этим курсам я наконец нашла
			направление, которое действительно меня заинтересовало и где я хочу развиваться в дальнейшем.<br>
			Могу с уверенностью сказать, что лето было продуктивным, интересным и очень полезным!
		`,
		author: 'Valeriya Zahayeuskaya',
		img: './assets/img/ph/female.jpeg',
	},
	{
		courseYear: 2020,
		text: `
			Хотелось бы выразить огромную благодарность всем людям, принимающим
			участие в подготовке данных курсов, сопровождение участников во время
			самого курса и конечно же поддержке студентов непосредственно после их
			окончания. Начнём с того, что информация на курсах подаётся в доступной,
			понятной и довольно интересной форме, скучать точно не придется.
			В начале она подается в большом объёме, так что придётся сесть вечерком
			и поразбираться. Отдельное спасибо Кириллу, который готов был разъяснить
			все интересующие нас вопросы и всегда приходил на помощь при возникновении
			каких-либо трудностей во время отработки полученных знаний на практике.
			В общем, информации, которая даётся на курсах, вполне достаточно
			для старта работы по направлению DWH, если будете уделять достойное
			внимание теории и выполнять необходимые лабораторные работы.
			Особое внимание хочется уделить коллективу в DATAMOLA)
			Здесь действительно собрались не только прекрасные специалисты,
			но и безумно интересные и отзывчивые люди, к  каждому из которых
			всегда можно было подойти пообщаться и получить дельные советы.
			Впечатления от курса исключительно положительные!
			Если хочешь интересно провести время, познакомиться с направлением DWH
			и пообщаться с людьми, увлеченнными своим делом, то точно стоит
			рассмотреть данные курсы. Отдельным плюсом хочется выделить
			возможность трудоустройства при успешном прохождении курсов.
			P.s. отдельное спасибо Юле за постоянно подстыпающиеся конфетки)
		`,
		author: 'Alina Sadovskaya',
		img: './assets/img/ph/female.jpeg',
	},
	{
		courseYear: 2021,
		text: `
			Обучение на курсах мне понравилось. Из плюсов можно отметить подбор
			материалов и практическую направленность. Обучение на курсах идёт быстро,
			тем самым подготавливает вас к реальным темпам разработки. Материал даётся
			глубже чем в вузах и намного глубже чем на платных курсах. Кирилл отличный
			лектор, материал даёт не сухо, слушать его интересно, если что-то не понятно,
			то всегда даст фидбэк. Отдельным плюсом стало то, что во время обучения на
			курсах нас не только учили писать код, но в первую очередь понимать внутреннее
			устройство базы данных, была проведена отличная аналогия с устройством работы
			компьютера, были даже даны основы сетей. Таким образом помимо sql вы получите
			для себя ещё очень много ценных знаний, которая пригодятся вам в любой сфере
			связанной с it, а так же прочувствуете на себе сможете потянуть быстрый
			разработки или нет.
		`,
		author: 'Anton Harkevich',
		img: './assets/img/ph/male.jpeg',
	},
	{
		courseYear: 2020,
		text: `
			Отличный курс, дает теоритические основы DWH и практические навыки в этой
			сфери( создания и построение своего собственного хранилища, создание SSIS-пакета
			для загрузки данных, SQL, Docker).На мой взгляд курс очень cжат во времени,
			поэтому неплохо иметь предварительные знания, самый главный плюс это
			возможность получить знания от действующих специалистов.
		`,
		author: 'Tanya Gnezdilova',
		img: './assets/img/ph/female.jpeg',
	},
	{
		courseYear: 2020,
		text: `
			Курсы в компании  возможно позволят тебе по завершению
			найти работу мечты, остаться при своих,
			и  быть готовым к тому, что тебя ждет в ближайшем будущем.
			По завершению платных курсов ты получишь опыт решения
			общих задач, и не всегда он будет соответствовать требованиям
			твоего будущего работодателя. О трудоустройстве после
			завершения этих курсов  могу сказать только по опыту друзей. Сложно!
			Я думаю, что я затронул только самые базовые отличия но вывод такой,
			я бы выбрал курсы в компании.
			Порой это и выглядит как собеседование длиною  два,
			а то и три мсяца,  приходится много и усердно работать,
			не только над задачами но и над собой. Все же для челоека
			который знает зачем он это делает, это лучший вариант.
			Что касается курсов именно в компании DATAMOLA,  если видите
			свободное местечно, не упустите  возможность попробовать свои силы.
			Если пройдете, вы не только получите возможность обучаться у
			топовых специалистов   решать актуальные задачи но и
			прочувстуете весь спектр эмоций от гонки,
			которая, для некоторых может изменить жизнь.
		`,
		author: 'Evgeniy Vrublevskiy',
		img: './assets/img/ph/male.jpeg',
	},
	{
		courseYear: 2020,
		text: `
			Data Camping позволил получить достаточно глубокие знания о хранилищах данных.
			Объем пройденного материала очень большой. Кроме того, интенсивные задания
			по практической части помогли закрепить полученные знания. В рамках курса
			я улучшила свои навыки sql.  Курс был полезным и мотивирующим на дальнейшее саморазвитие.
			Спасибо команде DataMola за проделанную работу!
		`,
		author: 'Oksana Yukho',
		img: './assets/img/ph/female.jpeg',
	},
	{
		courseYear: 2020,
		text: `
			Проходила курсы по DWH летом 20 года, осталась довольна подачей материала
			и программой курсов, огромный плюс, что всему обучали почти с нуля и
			можно было заполнить пробелы в понимании SQL. Конечно, курсы были
			достаточно интенсивные, такой объем материала в короткий срок,
			учитывая, что после каждого занятия которые проходили 5 дней в
			неделю, были лабораторные работы. В целом атмосфера на занятиях
			была дружелюбная, как от команды так и от студентов: все были
			очень замотивированы и готовы помочь. Так что огромное спасибо за возможность
			получить качественные знания и узнать многое о DWH от специалистов в этой сфере
		`,
		author: 'Katya Risunova',
		img: './assets/img/ph/female.jpeg',
	},
];
