import {Authors} from '@entities/common/authors';
import {ICourseSchedule} from '@entities/components/course-schedule/interfaces';

export const DATA_CAMPING_PLAN: ICourseSchedule.PlanItem[] = [
	{
		name: '1 Unit',
		title: 'Oracle database. Introduction to Data Warehousing',
		author: [Authors.Kiryl],
		preconditions: `
							*NIX systems, System administration and troubleshooting,
							Oracle DB installation, Basic SQL knowledge
		`,
		lect: {
			hours: 20,
			count: 10,
		},
		lab: {
			hours: 16,
			count: 9,
		},
	},
	{
		name: '2 Unit',
		title: 'Extract, transform and load (SQL, ETL DEV)',
		author: [Authors.Kiryl],
		preconditions: `
							*NIX systems, System administration and troubleshooting,
							Oracle DB installation, Basic SQL knowledge
		`,
		lect: {
			hours: 22,
			count: 11,
		},
		lab: {
			hours: 20,
			count: 11,
		},
	},
	{
		name: '3 Unit',
		title: 'SQL Server Integration Services (SSIS)',
		author: [Authors.Kiryl],
		preconditions: 'Basic SQL knowledge, Windows system administration basics',
		lect: {
			hours: 24,
			count: 15,
		},
		lab: {
			hours: 10,
			count: 5,
		},
	},
	{
		name: '4 Unit',
		title: 'Amazon Web Services. Introduction to Cloud computing.',
		author: [Authors.Kiryl],
		preconditions: 'Basic SQL & Python knowledge',
		lect: {
			hours: 2,
			count: 2,
		},
		lab: {
			hours: 2,
			count: 2,
		},
	},
	{
		name: '5 Unit',
		title: 'Cloud ETL Development.',
		author: [Authors.Kiryl],
		preconditions: '*NIX systems, Basic SQL & Python knowledge',
		lect: {
			hours: 2,
			count: 2,
		},
		lab: {
			hours: 2,
			count: 2,
		},
	},
];
