export const NUMBERS = {
	['zero']: 'zero',
	['one']: 'first',
	['two']: 'second',
	['three']: 'third',
	['four']: 'fourth',
	['five']: 'fifth',
	['six']: 'sixth',
	['seven']: 'seventh',
	['eight']: 'eighth',
	['nine']: 'ninth',
	['ten']: 'tenth',
	['eleven']: 'eleventh',
	['twelve']: 'twelfth',
	['thirteen']: 'thirteenth',
	['fourteen']: 'fourteenth',
	['fifteen']: 'fifteenth',
	['sixteen']: 'sixteenth',
	['seventeen']: 'seventeenth',
	['eighteen']: 'eighteenth',
	['nineteen']: 'nineteenth',
	['twenty']: 'twentieth',
};
