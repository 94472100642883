import {NgModule} from '@angular/core';
import {BrowserWindowContainer} from '@shared/components/browser-window/browser-window.container';
import {CommonModule} from '@angular/common';
import {BrowserWindowComponent} from '@shared/components/browser-window/browser-window.component';
import {BrowserWindowTopPanelComponent} from '@shared/components/browser-window/window-top-panel/browser-window-top-panel.component';
import {BrowserWindowViewportComponent} from '@shared/components/browser-window/window-viewport/browser-window-viewport.component';

@NgModule({
	declarations: [
		BrowserWindowContainer,
		BrowserWindowComponent,
		BrowserWindowTopPanelComponent,
		BrowserWindowViewportComponent,
	],
	exports: [BrowserWindowContainer],
	imports: [CommonModule],
})
export class BrowserWindowModule {}
